import { Environment } from './shared';

export const environment: Environment = {
  env: 'development',
  OM_UI_URL: '/om',
  PM_UI_URL: '/pm',
  COPILOT_UI_URL: '/copilot',
  ALTS_UI_URL: '/alts',
  COLLATERAL_UI_URL: '/collateral-management',
  BASE_URL: '',
  RENEW_TOKEN_BEFORE_MILLIS: 180000,
  WIDGETS: ['om', 'pm', 'copilot', 'collateral', 'alts']
};
